import Cookies, { type CookieAttributes } from 'js-cookie';

import { brandConfig } from '@hubcms/brand';
import { writeToSessionStorage } from '@hubcms/utils-browser';

import { generateUID } from './generate-uid';

const GLITR_COOKIE_ID_COOKIE_NAME = '_mhtc_cId';
const GLITR_SESSION_ID_COOKIE_NAME = '_mhtc_sId';

const getSetGlitrId = (glitrIdCookieName: string, glitrCookieOptions?: CookieAttributes) => {
  const cookieValue = Cookies.get(glitrIdCookieName);

  if (cookieValue) {
    /* setting the cookie again to update the expiration date on every page load */
    Cookies.set(glitrIdCookieName, cookieValue, glitrCookieOptions);

    return cookieValue;
  }

  const generatedUID = generateUID();
  Cookies.set(glitrIdCookieName, generatedUID, glitrCookieOptions);

  return generatedUID;
};

export const getSetGlitrCookieId = () => {
  /**
   * Cookie expires in 31 days
   */
  return getSetGlitrId(GLITR_COOKIE_ID_COOKIE_NAME, { expires: 31, domain: brandConfig.domain });
};

export const getSetGlitrSessionId = () => {
  /**
   * Cookie expires in 30 minutes. To express this in days we divide by the amount of minutes in a day (24 hours × 60 minutes).
   */
  const id = getSetGlitrId(GLITR_SESSION_ID_COOKIE_NAME, { expires: 30 / (24 * 60), domain: brandConfig.domain });
  writeToSessionStorage('sessionid', id);

  return id;
};
