import { mergeDeepRight } from 'rambda';

import type { BrandConfig } from './brandConfig';
import { DEFAULT_BRAND_CONFIG } from './defaultBrandConfig';

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export function createBrandConfig(brandConfig: DeepPartial<BrandConfig>): BrandConfig {
  return mergeDeepRight(DEFAULT_BRAND_CONFIG, brandConfig);
}
