import { enGB } from 'date-fns/locale/en-GB';

import { createBrandConfig } from '@hubcms/domain-brand-config';

import ePaperImage from './e-paper.png';

export const brandConfig = createBrandConfig({
  archiveTitle: 'Archive',
  authMethod: 'none',
  authorPrefix: 'author/',
  brandCode: 'hub',
  dateFnsLocale: enGB,
  domain: 'hubgazette.com',
  enablePersonalizedArticleList: false,
  ePaperLinkImage: {
    // Next.js and Storybook handle static image data differently. For Next.js, assign data.src. For Storybook, use fallback.
    height: 24,
    src: ePaperImage.src ?? ePaperImage,
    width: 32,
  },
  ePaperLinkLabel: 'E-Paper',
  gtmCodes: [{ code: 'GTM-5ZZP5H4' }, { code: 'GTM-W869F7D' }],
  headerDateFormat: 'd MMMM yyyy',
  isKachingNewAdKeyFormat: false,
  locale: 'en-GB',
  loginLabel: 'Login',
  logoutLabel: 'Logout',
  navigationConfig: {
    menuCloseLabel: 'Close menu',
  },
  platformTextToSpeechLabels: {
    notPlaying: 'Listen',
    playing: 'Stop reading',
  },
  searchPrefix: 'search/',
  tagsb2bPrefix: 'sponsored/',
  tagsPrefix: 'tags/',
  tickarooClientId: '635a927785885b5472797873',
  timeZone: 'Europe/London',
  weatherSectionPage: '/weather/',
});
