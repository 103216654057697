import { Icon } from '@mediahuis/chameleon-react';

import { brandConfig } from '@hubcms/brand';
import { NavigationItem } from '@hubcms/ui-navigation';

import { useWeatherIcon } from '../../hooks';

import styles from './weather-icon.module.scss';

function WeatherIcon({ wrCode, temperature }: { wrCode: number; temperature: string }) {
  const { icon, weatherSectionPage } = useWeatherIcon({ wrCode });
  return (
    <NavigationItem
      trackingBlock="Services middle"
      trackingName="weather"
      className={styles.weatherIcon}
      href={weatherSectionPage}
    >
      {icon && <Icon size={brandConfig.weatherIconSize} as={icon} />}
      <span>{temperature}°</span>
    </NavigationItem>
  );
}

export default WeatherIcon;
