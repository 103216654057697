import { Logo } from '@mediahuis/chameleon-react';
import cx from 'classnames';
import type { CSSProperties, FC } from 'react';

import { brandConfig } from '@hubcms/brand';
import type { NavColumn } from '@hubcms/domain-navigation';
import { Container } from '@hubcms/ui-container';

import { CopyrightBanner } from '../CopyrightBanner';
import { SocialLink } from '../SocialLink';

import FooterColumn from './FooterColumn';
import styles from './footer.module.scss';
import type { FooterProps } from './types';

type FooterColumnsProps = {
  columns: NavColumn[];
  largeOnly?: boolean;
};

const FooterColumns: FC<FooterColumnsProps> = ({ columns, largeOnly }) => {
  return (
    <div className={cx(styles.columns, { [styles.largeOnly]: largeOnly })} data-testid="footer-columns">
      {columns.map(({ heading, subItems }) => (
        <FooterColumn key={heading.uniqueId} heading={heading} subItems={subItems} />
      ))}
    </div>
  );
};

const Footer: FC<FooterProps> = ({ primaryNavColumns, secondaryNavColumns, socials, copyright }) => {
  return (
    <Container as="footer" className={cx(styles.footer, 'no-print')}>
      <Logo name={brandConfig.footerLogo} className={styles.logo} data-testid="footer-heading" />

      {primaryNavColumns.length || secondaryNavColumns.length || socials.length ? (
        <div className={styles.content}>
          <div>
            <FooterColumns columns={primaryNavColumns} />
            <div
              className={styles.socials}
              data-testid="footer-socials"
              style={{ '--x-socials-flex-wrap': socials.length > 4 ? 'wrap' : 'nowrap' } as CSSProperties}
            >
              {socials.map(({ uniqueId, brand, url, target }) => {
                const brandNameFinal = brand === 'twitter' ? 'x' : brand; // TODO: CW-1556
                return (
                  <SocialLink
                    trackingBlock="Footer"
                    trackingName={brandNameFinal}
                    key={uniqueId}
                    brand={brand}
                    href={url}
                    target={target}
                    data-testid={`social-link-${brandNameFinal}`}
                  />
                );
              })}
            </div>
          </div>

          <FooterColumns columns={secondaryNavColumns} largeOnly />
        </div>
      ) : null}

      <div>
        <hr className={styles.divider} />
        <CopyrightBanner items={copyright} data-testid="footer-copyright-banner" />
      </div>
    </Container>
  );
};

export default Footer;
