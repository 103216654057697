import { DateTime as ChameleonDateTime } from '@mediahuis/chameleon-react';

import { brandConfig } from '@hubcms/brand';

type DateTimeProps = {
  date: string;
  className?: string;
  format?: string;
};

const DateTime = (props: DateTimeProps) => {
  return <ChameleonDateTime locale={brandConfig.dateFnsLocale} timeZone={brandConfig.timeZone} {...props} />;
};

export default DateTime;
