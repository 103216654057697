/* eslint-disable max-lines */
import { BrandedHeading } from '@mediahuis/chameleon-react';
import { dateTimeDateFormat, dateTimeTimeFormat } from '@mediahuis/chameleon-theme-wl/tokens';
import { nl } from 'date-fns/locale/nl';

import type { BrandConfig } from './brandConfig';

export const DEFAULT_BRAND_CONFIG: BrandConfig = {
  accountConsentConfig: {
    acceptText: 'Ik wil liever relevante advertenties',
    declineText: 'Ik wil evenveel, maar algemene advertenties',
  },
  adunitBrandCode: 'hub',
  archiveTitle: 'Archief',
  articleCommentsType: 'none',
  articleHeadingSize: '2xl',
  articleLayoutConfig: {
    extraPageImageClass: 'fullWidthSm',
    featureArticleImageClass: 'contentWidthBleed',
    hasDivider: false,
    heroBlockLocation: 'belowMedia',
    heroblockPremiumIconLocation: 'label',
    introLocation: 'aboveMedia',
    newsArticleImageClass: 'fullWidthSm',
    opinionArticleImageClass: 'fullWidth',
    sponsoredArticleImageClass: 'fullWidthSm',
  }, // articleV1 param
  authMethod: 'ciamAuth0',
  authorPrefix: 'auteur/',
  b2bDMPMetaTagBrandCode: 'hub',
  b2bUrlBrandCode: 'hub',
  brandCode: 'wl',
  brandedHeaderSublinkIcon: undefined,
  breakingStoryblockHasDateTime: true,
  breakingStoryblockHeadTeaserImageRatio: 'fourFive',
  breakingStoryblockMiniTeaserHasImage: true,
  breakingStoryblockMiniTeaserHasLabel: false,
  buttonAppearanceMapper: {
    outline: 'tertiary',
    plain: 'default',
    primary: 'primary',
    secondary: 'secondary',
  },
  cacheTimeouts: {
    mostRead: {
      backgroundRefetch: 30 * 1000, // 30 seconds before the TTL expires
      ttlInMs: { error: 15 * 1000, ok: 2 * 60 * 1000, staleTTL: 2 * 60 * 60 * 1000 }, // OK: 2 minutes, Error: 15 seconds, Stale: 2 hours
    },
    neighbourCommunities: {
      backgroundRefetch: 30 * 1000, // 30 seconds before the TTL expires  },
      ttlInMs: { error: 15 * 1000, ok: 6 * 60 * 60 * 1000, staleTTL: 24 * 60 * 60 * 1000 }, // OK: 6 hours, Error: 15 seconds, Stale: 24 hours
    },
    nonFunctional: {
      backgroundRefetch: 5 * 1000, // 5 seconds before the TTL expires
      ttlInMs: { error: 1 * 60 * 1000, ok: 2 * 60 * 1000, staleTTL: 5 * 1000 }, // OK: 2 minutes, Error: 1 minute, Stale: 5 seconds
    },
    piano: {
      ttlInMs: { error: 2 * 60 * 1000, ok: 5 * 60 * 1000 },
    },
    rssArticleList: {
      backgroundRefetch: 30 * 1000, // 30 seconds before the TTL expires  },
      ttlInMs: { error: 15 * 1000, ok: 60 * 60 * 1000, staleTTL: 2 * 60 * 60 * 1000 }, // OK: 60 minutes, Error: 15 seconds, Stale: 2 hours
    },
    sportify: {
      backgroundRefetch: 30 * 1000, // 30 seconds before the TTL expires
      ttlInMs: { error: 15 * 1000, ok: 5 * 60 * 1000, staleTTL: 2 * 60 * 60 * 1000 }, // OK: 5 minutes, Error: 15 seconds, Stale: 2 hours
    },
    weather: {
      backgroundRefetch: 30 * 1000, // 30 seconds before the TTL expires
      ttlInMs: { error: 15 * 1000, ok: 10 * 60 * 1000, staleTTL: 24 * 60 * 60 * 1000 }, // OK: 10 minutes, Error: 15 seconds, Stale: 24 hours
    },
  },
  canDisplayLocationInIntro: false, // articleV1 param
  cimIdentifier: '',
  cimLanguage: '',
  customConsent: '',
  dateFnsLocale: nl,
  defaultDateFormat: dateTimeDateFormat,
  defaultDateFormatToday: dateTimeTimeFormat,
  defaultTeaserImageRatio: 'sixteenNine',
  domain: '',
  enableMySection: true,
  enableNonModalPaywallAutoplay: false,
  enablePersonalizedArticleList: true,
  enablePiano: true,
  ePaperLinkDescription: undefined,
  ePaperLinkHref: undefined,
  ePaperLinkHrefTarget: '_blank',
  ePaperLinkImage: undefined,
  ePaperLinkLabel: undefined,
  excludedRelationDomains: [],
  footerCopyrightLogoUrl: 'mediahuis-currentColor.svg#logo',
  footerLogo: 'logo-main-inverse',
  gtmCodes: [],
  hasAdsAbovePaywalledArticleHero: true,
  hasDividerInResultListLg: true,
  hasPullQuoteIcon: false,
  hasPullQuoteQuotationMarks: true,
  headerDateFormat: '',
  headerLogo: 'logo-main',
  heroBlockDateShowUpdateLabel: false, // articleV1 param
  heroBlockDateUpdateLabel: 'update:', // articleV1 param
  heroBlockIntroProps: {
    size: 'lg',
    weight: 'default',
  },
  heroBlockSocialIconSize: 'sm',
  imageCaptionProps: {
    font: 'default',
    size: 'sm',
  },
  infiniteArticleListLoadMoreLabel: 'Laad meer artikelen',
  infoblockHeadingSize: 'lg',
  isAppTopicsMenuEnabled: true,
  isAutomatedTeaserIntroShown: true,
  isHeroBlockSocialColored: false,
  isKachingNewAdKeyFormat: true,
  isLeaderboardAdHiddenOnMobile: false,
  isLegacyArticleGuidRedirectEnabled: false,
  isSubscriptionMenuEnabled: true,
  isTeaserDateShownConfig: {
    default: true,
  },
  isTopicsMenuEnabled: true,
  isWebcmsIdRedirectEnabled: false,
  listTeaserDateFormat: undefined,
  locale: 'nl-BE',
  loginLabel: 'Aanmelden',
  logoSlug: 'logo-square-main',
  logoutLabel: 'Uitloggen',
  mySectionCtaTextSize: 'sm',
  MySectionHeader: BrandedHeading,
  mySectionHeaderSize: 'lg',
  navigationConfig: {
    advertisement: undefined,
    asideMenuMainMenuItemFont: 'default',
    logoMobile: 'logo-main',
    logoMobileSize: 'sm',
    logoMobileScroll: undefined,
    logoPosition: 'left',
    logoServices: 'logo-main',
    logoServicesSize: undefined,
    logoTopics: 'logo-square-main',
    logoTopicsSize: undefined,
    menuCloseLabel: 'Menu sluiten',
    menuLabel: 'Menu',
  },
  opinionTeaserPremiumIconPosition: 'label',
  paragraphProps: {
    size: 'lg',
  },
  platformTextToSpeechLabels: {
    playing: 'Stop met lezen',
    notPlaying: 'Luister',
  },
  ratingElementSize: 'lg',
  searchPrefix: 'zoeken/',
  searchTermQuotationMark: false,
  sectionPageAreaOnePosition: 'left',
  sectionPageAreaOneTeasertype: 'default',
  sectionPageAreaTwoLayoutMobileApp: 'stacked',
  shouldExposePushCategoryAndPushSegment: false,
  showLabelOnEditorialArticle: true, // articleV1 param
  showLabelOnSponsoredArticle: true, // articleV1 param
  showSubLabelOnEditorialArticle: true, // articleV1 param
  showSubLabelOnSponsoredArticle: true, // articleV1 param
  sponsoredContentTeaserIsHighlighted: true,
  storyElementsToTakeIntoAccountForAds: [],
  subscriptionButtonAppearance: 'primary',
  tagsb2bPrefix: 'gesponsord/',
  tagsPrefix: 'tag/',
  teaserLabelPropConfig: {
    default: 'label',
  },
  themedStoryblockHasTopPadding: true,
  themesWithAlternativePremiumLogo: [],
  tickarooClientId: '',
  timeZone: 'Europe/Brussels',
  weatherIconSize: 'sm',
  weatherSectionPage: '/weer/',
};
